import * as React from 'react';
import _ from 'lodash';

/**
 * 判斷是否為空值
 * @param {*} _value
 * @returns {Boolean}
 */
export const isEmpty = (_value) => {
    if (Array.isArray(_value)) return !_value.length;
    else if (Object.prototype.toString.call(_value) === '[object Object]') return JSON.stringify(_value) === '{}';
    else return _value === null || _value === undefined || _value === '' || _value === 0;
};

/**
 * 判斷是否為無效
 * @param {*} _value
 * @returns {Boolean}
 */
export const isInvalid = (_value) => _value === undefined || _value === null;

/**
 * 判斷是否為有效值，非 null 或 undefined 或 是數值卻不是數字型別 其他皆為有效值
 * @param {*} _value
 * @returns {Boolean}
 */
export const isValid = (_value) => {
    if (_value === null) return false;
    if (_value === undefined) return false;
    if (Number.isNaN(_value)) return false;
    return true;
};

/**
 * 判斷是否為整數
 * @param {*} _n
 * @returns {boolean}
 */
export const isInt = (_n) => {
    return Number(_n) === _n && _n % 1 === 0;
};

/**
 * 判斷是否為小數
 * @param {*} _n
 * @returns {boolean}
 */
export const isFloat = (_n) => {
    return Number(_n) === _n && _n % 1 !== 0;
};

/**
 * 設置React.ref 的值
 * @param {any} ref
 * @param {any} value
 */
export const setRef = (ref, value) => {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
};

/**
 * 檢查數值是否為數字
 * @param {*} _value
 * @returns {Boolean}
 */
export const isNumber = (_value) => !(parseFloat(_value).toString() === 'NaN');

/**
 * 檢查ref內是否存在
 * @param {String} key
 * @param  {...React.MutableRefObject} objectRefs
 * @return {Boolean}
 */
export const checkRefFuncExists = (key, ...objectRefs) => {
    let isExists = true;
    const arrayRef = Array.from(objectRefs);
    if (arrayRef.length === 0 || String(key) === '') return false;
    arrayRef.forEach((_r) => {
        if (!(_r.current && Object.prototype.toString.call(_r.current[key]) === '[object Function]')) {
            isExists = false;
        }
    });
    return isExists;
};

/**
 * 檢查是否為當前元件
 * @param {*} elem
 * @param {String} curNames
 * @returns {Boolean}
 */
export const isCurElement = (elem, curNames) => {
    return React.isValidElement(elem) && curNames.indexOf(elem.type[curNames]) !== -1;
};

/*
 * Supports determination of isControlled().
 * Controlled input accepts its current value as a prop.
 *
 * @see https://facebook.github.io/react/docs/forms.html#controlled-components
 * @param value
 * @returns {Boolean} true if string (including '') or number (including zero)
 **/
export const hasValue = (value) => {
    return value != null && !(Array.isArray(value) && value.length === 0);
};

/**
 * Determine if field is empty or filled.
 * Response determines if label is presented above field or as placeholder.
 * @param {Object} obj
 * @param SSR
 * @returns {Boolean} False when not present or empty string.
 *                    True when any number or string with length.
 */
export const isFilled = (obj, SSR = false) => {
    return obj && ((hasValue(obj.value) && obj.value !== '') || (SSR && hasValue(obj.defaultValue) && obj.defaultValue !== ''));
};

/**
 * Determine if an Input is adorned on start.
 * It's corresponding to the left with LTR.
 * @param {Object} obj
 * @returns {Boolean} False when no adornments.
 *                    True when adorned at the start.
 */
export const isAdornedStart = (obj) => {
    return obj.startAdornment;
};

/**
 * Determines if a given element is a DOM element name (i.e. not a React component).
 * @param {React.ElementType} element
 * @returns {Boolean}
 */
export const isHostComponent = (element) => {
    return typeof element === 'string';
};

/**
 * Safe chained function.
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 * @param {...Function} funcs
 */
export const createChainedFunction = (...funcs) => {
    return funcs.reduce(
        (acc, func) => {
            if (func == null) {
                return acc;
            }
            return (...args) => {
                acc.apply(this, args);
                func.apply(this, args);
            };
        },
        () => {}
    );
};

/**
 * Corresponds to 10 frames at 60 Hz.
 * A few bytes payload overhead when lodash/debounce is ~3 kB and debounce ~300 B.
 * @param {Function} func
 * @param {Number} wait
 * @return {Function}
 */
export const debounce = (func, wait = 166) => {
    let timeout;
    const debounced = (...args) => {
        const later = () => {
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };

    debounced.clear = () => {
        clearTimeout(timeout);
    };
    return debounced;
};

/**
 * @param {Document} node
 * @returns
 */
export const ownerDocument = (node) => {
    return (node && node.ownerDocument) || document;
};

/**
 * @param {*} node
 * @returns {Window}
 */
export const ownerWindow = (node) => {
    const doc = ownerDocument(node);
    return doc.defaultView || window;
};

/**
 * @param {Object} rectangle
 * @param {*} vertical
 * @returns {Number}
 */
export const getOffsetTop = (rectangle, vertical) => {
    let offset = 0;

    if (isNumber(vertical)) {
        offset = vertical * 1;
    } else if (vertical === 'center') {
        offset = rectangle.height / 2;
    } else if (vertical === 'bottom') {
        offset = rectangle.height;
    }
    return offset;
};

/**
 * @param {Object} rectangle
 * @param {*} horizontal
 * @returns {Number}
 */
export const getOffsetLeft = (rectangle, horizontal) => {
    let offset = 0;

    if (isNumber(horizontal)) {
        offset = horizontal;
    } else if (horizontal === 'center') {
        offset = rectangle.width / 2;
    } else if (horizontal === 'right') {
        offset = rectangle.width;
    }

    return offset;
};

/**
 * A change of the browser zoom change the scrollbar size.
 * Credit https://github.com/twbs/bootstrap/blob/488fd8afc535ca3a6ad4dc581f5e89217b6a36ac/js/src/util/scrollbar.js#L14-L18
 */
export const getScrollbarSize = (doc) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
    const documentWidth = doc.documentElement.clientWidth;
    return Math.abs(window.innerWidth - documentWidth);
};

/**
 *
 * @props { [key: string]: unknown }
 * @propName {String}
 * @componentName {String}
 * @location {String}
 * @propFullName {String}
 * @returns  null | Error Message
 */
export const HTMLElementType = (props, propName, componentName, location, propFullName) => {
    const propValue = props[propName];
    const safePropName = propFullName || propName;
    if (propValue == null) return null;
    if (propValue && (propValue?.nodeType ?? -1) !== 1) {
        return new Error(
            // eslint-disable-next-line
            `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. ` + `Expected an HTMLElement.`
        );
    }
    return null;
};

/**
 * localStorage
 */
export const localUser = {
    get() {
        return JSON.parse(localStorage.getItem('user'));
    },
    set(user) {
        return localStorage.setItem('user', JSON.stringify(user));
    },
    getToken() {
        return localStorage.getItem('user-token');
    },
    setToken(key) {
        localStorage.removeItem('user');
        return localStorage.setItem('user-token', key);
    },
    hasData() {
        return !!localStorage.getItem('user');
    },
    updateAttr(attr, value) {
        let user = this.get();
        user[attr] = value;
        this.set(user);
    },
    logout() {
        return localStorage.removeItem('user');
    },
};

/**
 * 解析 query string
 * @param {string|[string]} name
 * @param {String} url ex: 'google.com.tw/page?p1=111&p2=333' or '?p1=111&p2=333'
 * @return {Object}
 */
export const getUrlParameter = (name, url) => {
    let namePack = [];
    let queryObject = {};
    let regex = /[?&]([\w]+)=([^&#]*)/g;
    let i;
    if (typeof name === 'string') {
        namePack.push(name);
    } else if (Array.isArray(name)) {
        namePack = [...name];
    } else {
        console.error(`First Arguments can't resolve,Please USE String or Array[...string]`);
        return {};
    }
    while ((i = regex.exec(url)) !== null) queryObject[i[1]] = i[2];
    if (namePack.length !== 0) {
        return namePack.reduce(
            (acc, cur) => ({
                ...acc,
                [cur]: queryObject[cur],
            }),
            {}
        );
    } else {
        return queryObject;
    }
};

/**
 * @param {Object} queryObject
 * @returns string
 */
export const objectToQueryString = (queryObject) => {
    return '?' + new URLSearchParams(queryObject).toString();
};

export const paramsToObject = (params) => {
    let entries = new URLSearchParams(params);
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
};

/**
 * 格式化數字轉為金錢顯示方式 ex: 1000000 -> 1,000,000
 */
export const formatCurrencyFn = (target, { symbol = '', point = 0 } = {}) => {
    let num = parseFloat(target, 10);
    if (_.isNaN(num)) {
        // console.log('Allen: Your num is invalid,please give Number');
        return '';
    }
    num = num.toLocaleString('en-US');
    if (point) num = num.toFixed(point);
    if (symbol) num = symbol + num;
    return num;
};

/**
 * 檢查是否為必填，拋出必填訊息
 * @params {...ReactRef}
 * @reutrns {void}
 */
export const refIsRequiredError = (...args) => {
    const chkReq = args.reduce((acc, cur) => {
        let newAcc = acc;
        if (cur && cur.current && cur.current.isError) {
            const err = cur.current.isError();
            if (!newAcc) newAcc = err;
        } else if (cur && cur.isError) {
            const err = cur.isError();
            if (!newAcc) newAcc = err;
        } else if (cur && cur.current) {
            const err = refIsRequiredError(...Object.values(cur.current));
            if (!newAcc) newAcc = err;
        }
        return newAcc;
    }, false);
    return chkReq;
};

/**
 * Appends the ownerState object to the props, merging with the existing one if necessary.
 * @param elementType Type of the element that owns the `existingProps`. If the element is a DOM node, `ownerState` are not applied.
 * @param existingProps Props of the element.
 * @param ownerState
 */
export const appendOwnerState = (elementType, existingProps, ownerState) => {
    if (isHostComponent(elementType)) return existingProps;

    return {
        ...existingProps,
        ownerState: { ...existingProps.ownerState, ...ownerState },
    };
};

/**
 * 比較兩者陣列,匹配出相同的對象,拿取該對應value值
 * @param {Array} _corrArray        // 比較陣列 option
 * @param {Array} sourceArray       // 主要參考陣列 option
 * @param {String} _getValue        // 要匹配的名稱(key)
 * @param {String} _getKey          // 回傳需要的名稱(key)
 * @returns {String}                // ex 這,是,出,來,的,結,果
 */
export const arrayIntersection = (_corrArray = [], sourceArray = [], _getValue, _getKey) => {
    let cloneSource = _.cloneDeep(sourceArray);
    return cloneSource
        .reduce((acc, cur) => {
            let newAcc = [...acc];
            if (_corrArray.findIndex((v) => String(v) === String(cur[_getValue])) !== -1) {
                newAcc.push(cur[_getKey]);
            }
            return newAcc;
        }, [])
        .join(',');
};

/**
 * @param {String} targetString
 * @returns {Array}
 */
export const stringToArray = (targetString) => {
    if (typeof targetString === 'string') return [targetString];
    else return targetString;
};

/**
 * 找出val在targetArray裡位於第幾個索引
 * @param {Array} targetArray
 * @param {String} targetKey
 * @param {String} targetValue
 * @returns {Number}
 */
export const findIndexFn = (targetArray = [], targetKey, targetValue) => {
    if (!Array.isArray(targetArray)) {
        console.error('Your first value is not Array');
        return -1;
    }
    return targetArray.findIndex((targetItem) => targetItem && String(targetItem[targetKey]) === String(targetValue));
};

/**
 * 顯示 option text
 * @param {[{ value: string}] | array} options
 * @param {String} value
 * @param {String} defaultVal
 * @returns String
 */
export const matchOptionsText = (options = [], value = '', defaultVal = '查無資料', keyName = 'text') => {
    return options.find((item) => String(item.value) === String(value))?.[keyName] ?? defaultVal;
};

/**
 * 複製文字
 */
export const textCopypaste = (_text) => {
    const selection = window.getSelection();
    const _span = document.createElement('span');
    const range = document.createRange();
    selection.removeAllRanges();
    _span.innerHTML = _text;
    document.body.appendChild(_span);
    range.selectNodeContents(_span);
    selection.addRange(range);
    document.execCommand('Copy');
    selection.removeAllRanges();
    document.body.removeChild(_span);
};

/**
 * 彈出連結下載
 */
export const linkDownload = (_href) => {
    window.open(_href);
};

/**
 * 物件 轉 QueryString
 */
export const serialize = (target) => {
    if (URLSearchParams) return new URLSearchParams(target).toString();
    let strAry = [];
    for (let _i in target)
        if (target.hasOwnProperty(_i)) {
            strAry.push(encodeURIComponent(_i) + '=' + encodeURIComponent(target[_i]));
        }
    return strAry.join('&');
};

/**
 * 合併陣列後增加屬性欄位
 * @param {Array} mainArray 主要option
 * @param {Array} minorArray 次要option
 * @param {string|array[...string]} equalValue 主要和次要相關聯的value值,做對應 否則沒對到該值就不會被複製
 * @param {string|array[...string]} addMinorKeyNames 從次要option中要合併的keyName
 */
export const mergeArrayByProperty = (mainArray = [], minorArray = [], equalValue, addMinorKeyNames) => {
    let keyNames;
    if (typeof addMinorKeyNames === 'string') keyNames = [addMinorKeyNames];
    else if (Array.isArray(addMinorKeyNames)) keyNames = [...addMinorKeyNames];
    else return mainArray;

    const mergeAry = mainArray.map((targetMain) => {
        let source = { ...targetMain };
        let newProperty = {};
        const minorIndex = minorArray.findIndex((targetMinor) => {
            let mainKey = equalValue,
                minorKey = equalValue;
            if (Array.isArray(equalValue)) {
                mainKey = equalValue[0];
                minorKey = equalValue[1];
            }
            return String(targetMinor[minorKey]) === String(targetMain[mainKey]);
        });

        if (minorIndex !== -1) {
            newProperty = keyNames.reduce((acc, minorString) => {
                let newAcc = { ...acc };
                Object.assign(newAcc, { [minorString]: minorArray[minorIndex][minorString] });
                return newAcc;
            }, {});
        }

        Object.assign(source, newProperty);
        return source;
    });
    return mergeAry;
};

/**
 * 打平巢狀陣列(註解：多維度打成一維度) 擷取所有 selectKey 相關的索引巢狀 資料
 * @param {Array} sourceList ex: [a[b[c[d][f]]]] === [a,b,c,d,e,f]
 * @param {String} selectKey default is children
 * @return {Array}
 */
export const flatDeepArray = (sourceList, selectKey = 'children') => {
    const deepArrayFn = (target) => {
        const { [selectKey]: children, ...other } = target;
        if (!children || !children.length) {
            return other;
        }
        return [other, _.flatMapDeep(children, deepArrayFn)];
    };
    return _.flatMapDeep(sourceList, deepArrayFn);
};

/**
 * getSelectOptions
 * @param {Array} options
 * @param {String} key
 * @param {String|Array} values
 * @returns {Array}
 */
export const getSelectOptions = (options, key, values) => {
    let valuePack = [];
    if (isInvalid(values) || values === '') return options;
    if (typeof values === 'string') valuePack = [values];
    else if (Array.isArray(values)) valuePack = [...values];
    else return [];
    return options.filter((target) => {
        return valuePack.indexOf(target[key]) !== -1;
    });
};

/**
 * 標準化字符處理
 * @param {String} str
 */
function stripDiacritics(str) {
    return typeof str.normalize !== 'undefined' ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : str;
}

/**
 * AutoComplete Filter use
 */
export const createFilterOptions = (config = {}) => {
    const { ignoreAccents = true, ignoreCase = true, limit, matchFrom = 'any', stringify, trim = false } = config;

    return (options, { inputValue, getOptionLabel }) => {
        let input = trim ? inputValue.trim() : inputValue;
        if (ignoreCase) {
            input = input.toLowerCase();
        }
        if (ignoreAccents) {
            input = stripDiacritics(input);
        }

        const filteredOptions = options.filter((option) => {
            let candidate = (stringify || getOptionLabel)(option);
            if (ignoreCase) {
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                candidate = stripDiacritics(candidate);
            }

            return matchFrom === 'start' ? candidate.indexOf(input) === 0 : candidate.indexOf(input) > -1;
        });

        return typeof limit === 'number' ? filteredOptions.slice(0, limit) : filteredOptions;
    };
};

/**
 * 顯示累積月/日 文字
 * @param {string | number} month
 * @param {string | number} days
 * @param {String} defaultText
 * @return {String}
 * example (1,6) -> 1個月6天
 */
export const displayDateLength = (month, days, defaultText = '無相關資料') => {
    if (!month && !days) return '';
    let text = '';
    if (month) text = text + month + '個月';
    if (month && days) text = text + '又';
    if (days) text = text + days + '天';
    if (!month && !days) text = defaultText;
    return text;
};

/**
 * 匹配router內的參數,引用第二參數內的key取代掉為value
 * @param {String} pathStr
 * @param {Object} targetData
 * @returns {String}
 * example
 *  first Params '/doc/membershipt/:people?/:memberID/overview'
 *  second Params { people:10, memberID:2 }
 *  result -> '/doc/membershipt/10/2/overview'
 */
export const pathRouterShit = (pathStr, targetData) => {
    const replaceKeyToValue = (targetString, source) => {
        const targetKeys = Object.keys(source);
        const newValue = targetKeys.reduce((acc, cur) => {
            let newTargetData = { ...acc };
            if (targetString.indexOf(`:${cur}`) !== -1) {
                newTargetData = { value: source[cur] };
            }
            return newTargetData;
        }, {});
        return newValue['value'];
    };

    const newPath = pathStr.split('/').map((s) => {
        return s.charAt(0) !== ':' ? s : replaceKeyToValue(s, targetData);
    });

    return newPath.join('/');
};

/**
 * 轉換字串為布林值
 * @param {String} targetString
 * @return {Boolean}
 */
export const parseBoolean = (targetString) => {
    if (!targetString) return !targetString;
    else {
        const tmpStr = String(targetString);
        if (tmpStr === 'true') return true;
        else if (tmpStr === 'false') return false;
        else return !!tmpStr;
    }
};

/**
 * 提取radio獨立值
 * @param {Array} radioResultOptions
 * @param {String} targetKey
 * @param {*} defaultValue
 * @returns {*}
 */
export const getRadioFieldResult = (radioResultOptions, targetKey, defaultValue = 0) => {
    return radioResultOptions.find(({ checked }) => checked)?.[targetKey] ?? defaultValue;
};

/**
 * 取得小數後幾位
 * @param {Number} num
 * @param {Number} fixed
 * @returns {Number}
 */

export const toFixed = (num, fixed = 2) => {
    if (!isNaN(num)) return Math.round(num * 10 ** fixed) / 10 ** fixed;
    return num;
};

/**
 * 小數轉百分比
 * @param {Number} num
 * @returns {Number}
 */
export const floatToPercentage = (num, config = {}) => {
    const { fixed = 2 } = config;
    if (!isNaN(num)) return toFixed(num * 100, fixed);
    return num;
};

/**
 * 百分比轉小數
 * @param {Number} num
 * @returns {Number}
 */

export const percentageToFloat = (num, config = {}) => {
    const { fixed = 4 } = config;
    let _fixed = fixed - 2;
    if (!isNaN(num)) return toFixed(num, _fixed) / 100;
    return num;
};

/**
 * 處理Component裡的錯誤訊息
 * @param {Object} target
 * @param {String} keyName
 * @returns {Object}
 */
export const getErrorModuleStatus = (target, keyName) => {
    let targetKey;

    if (_.isEmpty(target) || isEmpty(keyName)) {
        // console.error('Your Object is empty');
        return {};
    } else {
        targetKey = target[keyName];
    }
    if (!targetKey) return {};

    return {
        isError: !!targetKey,
        helperText: !!targetKey && (target[keyName]?.message ?? target[keyName]),
    };
};

/**
 * 計算字數, 點數, 判斷長短簡訊
 * @param {String} messageText
 * @returns {{ remainder: number , point: number }}
 */
export const messageCounts = (messageText = '') => {
    let isLongAuthority = 'oooxxxxxxxxxxxxxxxxxxxxoxxxxxxxx'.substring(0, 1) === 'o' ? true : false;
    let message = _.trim(messageText);
    var isChinese = checkChinese(message);
    let beforeReplaceAll = message;
    let afterReplaceAll = beforeReplaceAll.replace(/\r\n/g, '\n');
    let limit = 0;
    let remainder;
    let point = 1;

    isChinese ? (limit = 70) : (limit = 160);

    if (isLongAuthority) {
        //計算長度
        remainder = afterReplaceAll.length - stringFilterTrim(afterReplaceAll);
        point = smsCounts(afterReplaceAll.substring(0, afterReplaceAll.length - stringFilterTrim(afterReplaceAll)));
    } else {
        //計算長度
        remainder = limit - (afterReplaceAll.length - stringFilterTrim(afterReplaceAll));
    }
    return { remainder, point };
};

/**
 * 計算簡訊點數及判斷長短簡訊
 * @param {String} text
 * @returns {Number}
 */
export const smsCounts = (text) => {
    // let isLongAuthority = 'oooxxxxxxxxxxxxxxxxxxxxoxxxxxxxx'.substring(0, 1) === 'o' ? true : false;
    let counts = 0;
    let xLen = 0;
    let xWide = '';
    let longMsg = false;
    xLen = text.length;

    //當含有中文字且文字長度超過70為長簡訊 ,非中文長度超過160為長簡訊
    if (xLen > 0) {
        if (checkChinese(text) && xLen > 70) {
            longMsg = true;
        } else if (!checkChinese(xWide) && xLen > 160) {
            longMsg = true;
        } else {
            longMsg = false;
        }
    }

    //長簡訊判斷
    if (text.length <= 70) {
        counts = 1;
    } else if (checkChinese(text) && text.length > 70) {
        while (xLen > 0) {
            if (longMsg) {
                xWide = text.substring(0, 67);
            } else {
                xWide = text.substring(0, 160);
            }

            while (xWide.length > 67) {
                if (checkChinese(xWide)) {
                    //是中文字且文字長度是68,69,70則不刪字(非長簡訊)
                    if (!longMsg && xWide.length() > 67 && xWide.length() < 71) {
                        break;
                    } else if (longMsg) {
                        xWide = xWide.substring(0, xWide.length() - 1);
                    }
                }
            }
            if (xWide.length > 0) {
                text = text.substring(xWide.length);
                counts++;
            }
            xLen = text.length;
        }
    } else if (!checkChinese(text)) {
        while (xLen > 0) {
            if (longMsg) {
                xWide = text.substring(0, 153);
            } else {
                xWide = text.substring(0, 160);
            }

            while (xWide.length > 160) {
                if (!checkChinese(xWide)) {
                    //是純英數字且文字長度是154~160則不刪字(非長簡訊)
                    if (!longMsg && xWide.length() > 153 && xWide.length() < 161) {
                        break;
                    } else if (longMsg) {
                        xWide = xWide.substring(0, xWide.length() - 1);
                    }
                }
            }
            if (xWide.length > 0) {
                text = text.substring(xWide.length);
                counts++;
            }
            xLen = text.length;
        }
    }
    return counts;
};

/**
 * 判斷是否有中文
 * @param {String} text
 * @returns boolean
 */
export const checkChinese = (text) => {
    let textLength = text.length;
    text = encodeURIComponent(text);
    const chineseLength = text.replace(/%[A-F\d]{2}/g, 'U').length;
    if (textLength !== chineseLength) {
        return true;
    }
    return false;
};

/**
 * 過濾訊息結尾的空白與斷行，不予計算字數。
 * @param {String} text
 * @returns number
 */
export const stringFilterTrim = (text) => {
    let deductNum = 0;
    for (let i = text.length - 1; i >= 0; i--) {
        if (text.charAt(i) === '\u0020' || text.charAt(i) === '\n') {
            deductNum++;
        } else {
            break;
        }
    }
    return deductNum;
};

/**
 * 判斷是否為安著
 * @returns {Boolean}
 */
export const getIsAndroid = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isMobile = /Mobile/i.test(navigator.userAgent);
    return isAndroid || isMobile;
};

/**
 * 隨機亂碼
 * @param {Number} max
 * @returns {Number}
 */
export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};


/**
 * 隨機亂碼字串
 * @param {number} length
 * @returns {string}
 */
export const generateRandomString = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(array[i] % charactersLength);
    }

    return result;
}

/**
 * 檢查Element(元件)是否有勾選
 * @param {Object} targetArray
 * @param {Object} targetData
 * @returns {Array}
 */
export const inputCheckedAction = (targetArray, targetData, filterKey) =>
    targetArray.reduce((acc, cur) => {
        const _index = findIndexFn(targetData, filterKey, cur.value); // todo
        let newAcc = [...acc];
        if (cur.checked && _index !== -1) newAcc.push(targetData[_index]);
        return newAcc;
    }, []);

/**
 * 是否為正確的信用卡卡號
 * @param {String} value
 * @returns {Boolean}
 */
export const isCorrectCardNo = (value) => {
    // ^(?:4[0-9]{12}(?:[0-9]{3})?          # Visa
    //  |  5[1-5][0-9]{14}                  # MasterCard
    //  |  (?:2131|1800|35\d{3})\d{11}      # JCB
    // )$
    const reg = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(?:2131|1800|35\d{3})\d{11})$/gm;
    return reg.test(value);
};

/**
 * 是否為正確的手機格式 (09開頭)
 * @param {String} value
 * @returns {Boolean}
 */
export const isMobileNumber = (value) => {
    const reg = /^(09)[0-9]{8}$/gm;
    return reg.test(value);
};

/**
 * 是否為統編長度 8碼數字
 * @param {String} value
 * @returns {Boolean}
 */
export const isTaxIDLength = (value) => {
    const reg = /^[\d]{8}$/gm;
    return reg.test(value);
};

/**
 * 是否為統編規則
 * @param {String} taxID
 * @returns {Boolean}
 */
export const isCorrectTaxID = (taxID) => {
    const logicalMultiplier = [1, 2, 1, 2, 1, 2, 4, 1]; // 邏輯乘績
    const _tax = taxID.split('');
    const length = 8,
        checkNumber = 5;
    let sum = 0;

    for (let i = 0; i < length; i++) {
        let p = logicalMultiplier[i] * parseInt(_tax[i], 10);
        let s = p / 10 + (p % 10);
        sum += s;
    }

    // 當統一編號第7位數為7時，乘積之和最後第二位數取0，或1均可，其中之一 『和』能被整除。
    return sum % checkNumber === 0 || (sum + (1 % checkNumber) === 0 && String(_tax[6]) === '7');
};

/**
 * 是否為自然人憑證 (2碼英文字母加上14碼數字)
 * @param {String} value
 * @returns {Boolean}
 */
export const isCitizenDigitalCertificate = (value) => {
    const reg = /^[a-z][A-Z]{2}[0-9]{14}$/gm;
    return reg.test(value);
};

/**
 * 是否為手機條碼 (斜線(/)加上7碼數字或大寫字母)
 * @param {String} value
 * @returns {Boolean}
 */
export const isMobileBarcode = (value) => {
    // 第1碼為【/】;
    // 其餘7碼則由數字【0-9】大寫英文【A-Z】與特殊符號【+】【-】【.】
    const reg = /^\/[0-9A-Za-z.+-]{7}$/gm;
    return reg.test(value);
};
